import { toIsoString, sha256 } from './logic.js'
export default class SeezSdk {
  #handoutToken = null
  #accessToken = null
  #refreshPromise = null
  #alreadyChecked = false
  #baseHeaders = { 'Accept': '*/*', 'Content-Type': 'application/json' }
  #customHeaders = {}
  #favorites = JSON.parse(localStorage.getItem('favorites')) ?? []
  #savedSearches = JSON.parse(localStorage.getItem('searches')) ?? []
  #lastUserId = null // to deduplicate analytics identify tracking
  #userSubscriptionHandlers = []
  #buyButtonsObserver = new MutationObserver(mutations => {
    if (mutations.some(m => m.attributeName === 'data-listing-id')) this.injectSeezOnlineBuying(true)
  })
  #userPIITracking = false

  constructor() {
    this.clientId = document?.querySelector('[data-seez-client-id]')?.getAttribute('data-seez-client-id')
    if (this.clientId) this.#baseHeaders['Client-Id'] = this.clientId
    this.#parseHandoutFromUrl()
    let anonymousId = localStorage.getItem('Seez-Anonymous-Id')
    if (anonymousId == null) {
      anonymousId = crypto.randomUUID()
      localStorage.setItem('Seez-Anonymous-Id', anonymousId)
    }
    this.#baseHeaders['Seez-Anonymous-Id'] = anonymousId
    let sessionId = sessionStorage.getItem('Seez-Session-Id')
    if (sessionId == null) {
      const cookies = document.cookie?.split('; ').map(p => p.split('=')).reduce((t, c) => ({ ...t, [c[0]]: c[1] }), {}) ?? {}
      sessionId = cookies['seez-session-id'] ?? crypto.randomUUID()
      sessionStorage.setItem('Seez-Session-Id', sessionId)
    }
    this.#baseHeaders['Seez-Session-Id'] = sessionId
    this.#baseHeaders['Client-Lang'] = document.querySelector('html')?.getAttribute('lang')
    this.#injectCustomStyles()
    this.createWebSocket(this.clientId)
  }

  customizeStyles() {
    console.warn('Deprecated. Custom styles are loaded automatically during "beforeCreate"')
  }

  //#region language
  #languageResources = {}
  loadLanguageResources(language) {
    if (!(language in this.#languageResources)) {
      this.#languageResources[language] = fetch(`${import.meta.env.VITE_TRANSLATIONS_URL}/${language}.json`).then(r => r.json()).catch(e => console.error(e))
    }
    return this.#languageResources[language]
  }
  //#endregion

  //#region modals
  showMessage(content, closable = true) {
    let promiseResolve = null

    function closed() {
      document.body.querySelectorAll('seez-sdk-modal').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve()
    }

    const modalComponent = document.createElement('seez-sdk-modal')
    modalComponent.setAttribute('closable', closable)
    modalComponent.innerHTML = content
    document.body.appendChild(modalComponent)
    modalComponent.addEventListener('close', closed)

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }

  showModal(tag, props = {}, closable = true, slots = {}) {
    let promiseResolve = null

    function closed(result) {
      document.body.removeChild(result.target)
      if (promiseResolve) promiseResolve(result.detail[0])
    }

    const modalComponent = document.createElement('seez-sdk-modal')
    modalComponent.setAttribute('closable', closable)
    const component = document.createElement(tag)
    for (const key in props) component.setAttribute(key, props[key])
    for (const key in slots) {
      const s = document.createElement('div')
      s.setAttribute('slot', key)
      s.innerHTML = slots[key].startsWith('#') ? document.querySelector(slots[key])?.innerHTML : slots[key]
      component.appendChild(s)
    }
    modalComponent.appendChild(component)

    document.body.appendChild(modalComponent)
    modalComponent.addEventListener('close', closed.bind(this))

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }
  //#endregion

  //#region Buy Button
  async getLinksForListings(externalIds) {
    const uniqueExternalIds = [...new Set(externalIds)]
    let seezLinks = []
    try {
      const response = await this.queryApi(
        'mutation l($ids: [String]){generateLinksFromExternalIds(externalId: $ids)}',
        { ids: uniqueExternalIds }
      )
      seezLinks = response.generateLinksFromExternalIds
    } catch (error) {
      console.error(error)
    }
    return externalIds.reduce((t, c, i) => { if (seezLinks[i]) t[c] = seezLinks[i]; return t }, {})
  }

  async injectSeezOnlineBuying(forceRefresh = false, callback) {
    const selector = '[data-listing-id]:not(.statusReady):not(.statusError)'
    const tags = [...document.querySelectorAll(forceRefresh ? '[data-listing-id]' : selector)]
    if (tags.length > 0) {
      tags.forEach(t => t.classList.remove('statusReady', 'statusError'))
      tags.filter(t => !t.classList.contains('customStyles')).forEach(t => t.style.visibility = 'hidden')

      const links = await this.getLinksForListings(tags.map(t => t.getAttribute('data-listing-id')))
      for (const tag of tags) {
        const externalId = tag.getAttribute('data-listing-id')
        tag.classList.add(externalId in links ? 'statusReady' : 'statusError')
        if (externalId in links) {
          if (tag.tagName === 'A') {
            tag.href = links[externalId]
          } else {
            tag.onclick = () => window.location = links[externalId]
          }
          if (!tag.classList.contains('customStyles')) tag.style.visibility = null
        }
      }
    }
    this.#buyButtonsObserver.disconnect()
    tags.forEach(t => this.#buyButtonsObserver.observe(t, { attributes: true }))
    if (callback) callback(tags)
  }
  //#endregion

  //#region Session
  async requestCode(email, language) {
    const url = `${import.meta.env.VITE_AUTH_URL}/otp?email=${encodeURIComponent(email)}${language ? `&language=${language}` : ''}`
    const requestOTPResponse = await fetch(url)
    return requestOTPResponse.json()
  }

  #navigateToPost(url, payload) {
    var form = document.createElement('form')
    form.style.visibility = 'hidden'
    form.method = 'POST'
    form.action = url
    for (const key in payload) {
      var input = document.createElement('input')
      input.name = key
      input.value = payload[key]
      form.appendChild(input)
    }
    document.body.appendChild(form)
    form.submit()
  }

  async loginWithOTP(email, otp, acceptsMarketing, redirectUrl) {
    const payload = {
      method: 'POST',
      headers: { 'Accept': '*/*', 'Content-Type': 'application/json', },
      body: JSON.stringify({ email: email, otp: otp })
    }
    const response = await fetch(import.meta.env.VITE_AUTH_URL + '/validate', payload)
    const { valid } = await response.json()
    if (!valid) throw new Error('Invalid email/OTP')
    const url = import.meta.env.VITE_AUTH_URL + '/login?redirect=' + encodeURIComponent(redirectUrl ?? window.location)
    this.#navigateToPost(url, { email: email, otp: otp, marketing: acceptsMarketing })
  }

  async loginWithOTPAjax(email, otp, acceptsMarketing) {
    const payload = {
      method: 'POST',
      headers: { 'Accept': '*/*', 'Content-Type': 'application/json', },
      body: JSON.stringify({ email: email, otp: otp, marketing: acceptsMarketing })
    }
    const response = await fetch(import.meta.env.VITE_AUTH_URL + '/authenticate', payload)
    const { valid, refreshToken } = await response.json()
    if (!valid) throw new Error('Invalid email/OTP')
    localStorage.setItem('refresh_token', refreshToken)
    await this.#refreshAccessToken()
  }

  #parseHandoutFromUrl() {
    var url = new URL(window.location)
    const ht = url.searchParams.get('ht')
    if (ht && this.#isTokenValid(ht)) {
      this.#handoutToken = ht
      url.searchParams.delete('ht')
      if (url.hash === '#_=_') url.hash = ''
      window.history.replaceState({}, '', url.toString())
    }
  }

  async logout(redirectUrl) {
    let trackingPromise = this.track('logout')
    this.#cleanUserDetails()
    localStorage.removeItem('refresh_token')
    console.log('waiting track')
    await trackingPromise
    console.log('track completed')
    if (redirectUrl) window.location = redirectUrl
    else window.location.reload()
    // window.location = import.meta.env.VITE_AUTH_URL + '/logout?redirect=' + encodeURIComponent(window.location)
  }


  showLogin(bannerState, closable = true, slots) {
    return this.showModal('seez-sdk-login-form', null, closable, slots)
  }

  closeLogin() { document.body.querySelectorAll('seez-sdk-login').forEach(x => x.parentNode.removeChild(x)) }


  showLogout(redirectUrl) {
    let promiseResolve = null

    function closed(result) {
      document.body.querySelectorAll('seez-sdk-logout').forEach(x => x.parentNode.removeChild(x))
      if (result.detail[0] === true) this.logout(redirectUrl).then(() => { if (promiseResolve) promiseResolve(result.detail[0]) })
      else if (promiseResolve) promiseResolve(result.detail[0])
    }

    const logoutComponent = document.createElement('seez-sdk-logout')
    document.body.appendChild(logoutComponent)
    logoutComponent.addEventListener('close', closed.bind(this))

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }

  async #refreshAccessToken() {
    try {
      // const refreshResponse = await fetch(import.meta.env.VITE_AUTH_URL + '/refresh', { credentials: 'include', mode: 'cors' })
      if (this.#handoutToken) {
        const payload = { method: 'POST', headers: { 'Accept': '*/*', 'Content-Type': 'application/json', }, body: JSON.stringify({ handoutToken: this.#handoutToken }) }
        const response = await fetch(import.meta.env.VITE_AUTH_URL + '/handout', payload)
        const { refreshToken } = await response.json()
        localStorage.setItem('refresh_token', refreshToken)
      }
      const rt = localStorage.getItem('refresh_token')
      if (rt) {
        const payload = { method: 'POST', headers: { 'Accept': '*/*', 'Content-Type': 'application/json', }, body: JSON.stringify({ refreshToken: rt }) }
        const refreshResponse = await fetch(import.meta.env.VITE_AUTH_URL + '/refresh', payload)
        const accessTokenRes = await refreshResponse.text()
        if (accessTokenRes.trim().startsWith('{')) { // Response body is JSON (NEW /refresh response)
          const { accessToken, refreshToken } = JSON.parse(accessTokenRes)
          if (refreshToken != null) localStorage.setItem('refresh_token', refreshToken)
          this.#accessToken = this.#isTokenValid(accessToken) ? accessToken : null
        } else this.#accessToken = this.#isTokenValid(accessTokenRes) ? accessTokenRes : null // rsponse body is raw accessBody as string (OUTDATED /refresh response)
        this.#invokeHandlers()
      }
      if (this.#handoutToken) {
        await this.#retrieveUserDetails()
        this.#handoutToken = null
      }
    } catch (error) {
      this.#accessToken = null
      this.#invokeHandlers()
      console.error(error)
    }
    this.#alreadyChecked = true
    return this.#accessToken
  }

  #parseJwt(jwt) {
    try {
      const base64Url = jwt.split('.')[1]
      const base64 = base64Url.replace('-', '+').replace('_', '/')
      const claimsString = atob(base64)
      return JSON.parse(claimsString)
    } catch (error) {
      return null
    }
  }

  #isTokenValid(token) {
    try {
      const jwtRegEx = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/
      if (token == null || !jwtRegEx.test(token)) return false
      const { exp } = this.#parseJwt(token)
      const now = Math.floor((new Date().getTime() + 1) / 1000)
      if ((exp - now) < 30) return false
      return true
    } catch (error) {
      return false
    }
  }

  getAccessToken(forceRefresh) { // unify promise
    if (forceRefresh) {
      this.#alreadyChecked = false
      this.#accessToken = null
    }

    if (this.#alreadyChecked && this.#accessToken == null) return null
    if (this.#isTokenValid(this.#accessToken)) return this.#accessToken
    if (this.#refreshPromise == null) {
      this.#refreshPromise = this.#refreshAccessToken()
      this.#refreshPromise.then(() => this.#refreshPromise = null)
    }
    return this.#refreshPromise
  }

  async getCurrentUser(forceRefresh, track = true) {
    const at = await this.getAccessToken(forceRefresh)
    const user = this.#parseJwt(at)
    if (track) {
      if (user) this.identify({ userId: user.id, name: user.name, email: user.email, marketing: user.marketing, loginStatus: true, phone: user.phone ?? null, })
      else if (this.analytics?.()?.user?.()?.id?.() != null) this.analytics?.().reset?.()
    }
    return user
  }

  #getCurrentUserSync() { //gets the user without fetching (returns null if it's expired)
    if (this.#isTokenValid(this.#accessToken)) return this.#parseJwt(this.#accessToken)
    return null
  }

  userSubscribe(callback) {
    this.#userSubscriptionHandlers.push(callback)
    const user = this.#getCurrentUserSync()
    callback(user)
    this.getAccessToken()
    return user
  }

  userUnsubscribe(callback) {
    const index = this.#userSubscriptionHandlers.indexOf(callback)
    if (index > -1) this.#userSubscriptionHandlers.splice(index, 1)
  }

  async #invokeHandlers() {
    if (this.#userSubscriptionHandlers.length === 0) return
    const user = this.#getCurrentUserSync()
    await Promise.allSettled(this.#userSubscriptionHandlers.map(h => h(user)))
  }
  //#endregion

  //#region api
  set customHeaders(value) { this.#customHeaders = value ?? {} }
  get customHeaders() { return this.#customHeaders }


  async queryApi(query, variables, signal) {
    const at = await this.getAccessToken()
    const body = { query: query.replace(/[\n,\s]\s*/gm, ' ') }
    if (variables) body.variables = variables
    const request = {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        ...this.#baseHeaders,
        'Local-Time': toIsoString(new Date()),
        //TODO: add language after enabling it in the CORS config
        ...this.#customHeaders
      }
    }
    if (at) request.headers.authorization = `Bearer ${at}`
    if (signal) request.signal = signal

    const response = await fetch(import.meta.env.VITE_API_URL, request)
    const result = await response.json()
    if (result.errors)
      throw new Error(result.errors.map(e => e.message).join('\r\n'))
    return result.data

  }

  static vueQueryMixin = {
    data() {
      return {
        abortController: new AbortController()
      }
    },
    beforeDestroy() {
      this.abortController?.abort()
    },
    methods: {
      queryApi(query, variables) {
        return new Promise((resolve, reject) => {
          window.seezSdk.queryApi(query, variables, this.abortController?.signal)
            .then(resolve)
            .catch(e => {
              if (e.name !== 'AbortError')
                reject(e)
            })
        })
      },
    }
  }

  async uploadFile(folder, file, isPublic = false, removeExtension = false) {
    if (folder == null || folder === '') throw new Error('Invalid folder')
    if (file == null) throw new Error('Invalid file')
    const safeFolder = folder.toLowerCase().replace(/[\s-@]+/g, '-').replace(/^-+/, '').replace(/-+$/, '')
    let safeFilename = encodeURIComponent(file.name.toLowerCase().replace(/[\s-@]+/g, '-').replace(/^-+/, '').replace(/-+$/, ''))
    safeFilename = safeFilename.replace('.', `${Date.now()}.`) // ensure uniqueness
    const extensionStart = safeFilename.lastIndexOf('.')
    if (removeExtension && extensionStart >= 0) safeFilename = safeFilename.substring(0, extensionStart)
    const key = `${safeFolder}/${safeFilename}`
    const { getUploadUrl } = await window.seezSdk.queryApi(`mutation {getUploadUrl(fileName: "${key}", isPublic: ${isPublic})}`)
    await fetch(getUploadUrl, { method: 'PUT', body: file, headers: { 'Content-Type': file.type } })
    return key
  }

  async uploadOrderDocument(orderId, file, type) {
    const query = `query getUrl($orderId: ID!, $input: OrderDocumentUploadInput) { 
      getOrderDocumentUploadUrl(orderId: $orderId document: $input) {
        url
        document { name fileName createdOn type }
      }
    }`
    if (file == null) throw new Error('Invalid file')
    const name = encodeURIComponent(file.name.toLowerCase().replace(/[\s-@]+/g, '-').replace(/^-+/, '').replace(/-+$/, ''))
    const input = { name, type }
    const { getOrderDocumentUploadUrl: { url, document } } = await window.seezSdk.queryApi(query, { orderId, input })
    await fetch(url, { method: 'PUT', body: file, headers: { 'Content-Type': file.type, 'x-amz-server-side-encryption': 'aws:kms' } })
    return { ...document, fileSize: file.size, fileType: file.type }
  }

  async uploadTradeInDocument(tradeInId, file, type) {
    const query = `query getUrl($tradeInId: ID!, $input: TradeInDocumentUploadInput) { 
      getTradeInDocumentUploadUrl(tradeInId: $tradeInId document: $input) {
        url
        document { name fileName createdOn type }
      }
    }`
    if (file == null) throw new Error('Invalid file')
    const name = encodeURIComponent(file.name.toLowerCase().replace(/[\s-@]+/g, '-').replace(/^-+/, '').replace(/-+$/, ''))
    const input = { name, type }
    const { getTradeInDocumentUploadUrl: { url, document } } = await window.seezSdk.queryApi(query, { tradeInId, input })
    await fetch(url, { method: 'PUT', body: file, headers: { 'Content-Type': file.type, 'x-amz-server-side-encryption': 'aws:kms' } })
    return { ...document, fileSize: file.size, fileType: file.type }
  }

  async getListingDetails(id, fields = '') {
    const DEFAULT_QUERY = `{listing(id:${id}) {id name variant model{name family {name brand{name}}} currency retailPrice{value} state imageIds images targetSite{name}}}`
    const LISTING_FRAGMENT = `fragment ListingFields on Listing {${fields}}`
    const FRAGMENT_QUERY = `${LISTING_FRAGMENT}, {listing(id:${id}) {id name variant model{name family {name brand{name}}} currency retailPrice{value} state images targetSite{name},...ListingFields}}`
    const query = fields ? FRAGMENT_QUERY : DEFAULT_QUERY

    const { listing } = await this.queryApi(query)
    listing.brand = listing.model.family.brand // for backwards compatibility
    listing.priceRetail = listing.retailPrice.value // for backwards compatibility
    return listing
  }
  //#endregion

  //#region Favorites
  getFavorites() {
    return this.#favorites
  }

  async #persistFavorites() {
    localStorage.setItem('favorites', JSON.stringify(this.#favorites))
    if (await this.getCurrentUser(null, false)) {
      const result = await this.queryApi('mutation sf($ids: [ID]) {saveAllFavorites(listingIds: $ids)}', { ids: this.#favorites })
      const savedIds = result.saveAllFavorites.map(x => parseInt(x))
      localStorage.setItem('favorites', JSON.stringify(savedIds))
    }
  }

  // export function getDb(name, version) {
  //   let dbReq = indexedDB.open(name, version)

  //   return new Promise((resolve, reject) => {
  //     dbReq.onupgradeneeded = (event) => {
  //       let notes = event.target.result.createObjectStore('notes', { autoIncrement: true })
  //       console.log(notes)
  //       resolve(event.target.result)
  //     }
  //     dbReq.onsuccess = (event) => resolve(event.target.result)
  //     dbReq.onerror = reject
  //   })
  // }

  // export async function getFavorites() {
  //   const db = await getDb('seez', 1)
  //   let tx = db.transaction(['favorites'], 'readonly');
  //   let store = tx.objectStore('favorites');
  //   let req = store.get(1);
  // }

  setFavorite(listingId, favorited) { //if not favorited is specified 'toggle' is assumed 
    const id = parseInt(listingId)
    const isFavorited = (typeof favorited === 'boolean') ? favorited : !this.#favorites.includes(id)
    if (isFavorited === false) {
      this.#favorites = this.#favorites.filter(x => x !== id)
      this.#persistFavorites()
    } else if (!this.#favorites.includes(id)) {
      const newList = [...this.#favorites, id]
      newList.sort()
      this.#favorites = newList
      this.#persistFavorites()
    }
    return isFavorited
  }

  static vueFavoritesMixin = {
    data() {
      return {
        favorites: window.seezSdk.getFavorites()
      }
    },
    methods: {
      setFavorite(listingId, favorited) {
        const f = window.seezSdk.setFavorite(listingId, favorited)
        this.favorites = window.seezSdk.getFavorites()
        return f
      }
    }
  }
  //#endregion

  //#region Saved Searches
  getSavedSearches() {
    return this.#savedSearches
  }

  #persistsSavedSearches() {
    localStorage.setItem('searches', JSON.stringify(this.#savedSearches))
    window.dispatchEvent(new CustomEvent('saved-searches-changed', {
      detail: {
        numSearch: this.#savedSearches?.length
      }
    }))
  }

  async addSearchInServer(name, filter) {
    const query = 'mutation saveSearch($name: String!, $filter: ListingFiltersInput!) { saveSearch(searchName: $name, filterParameters: $filter) {id} }'
    const { saveSearch } = await window.seezSdk.queryApi(query, { name: name, filter: filter })
    const newId = saveSearch[saveSearch.length - 1].id
    return newId
  }

  async addSearch(name, filter) {
    const minId = this.#savedSearches.reduce((t, c) => Math.min(c.id, t), 0)
    const newList = [...this.#savedSearches, { id: minId - 1, name: name, filterParameters: filter }] // creates negative index so the server knows it should be 'seq'
    this.#savedSearches = newList
    this.#persistsSavedSearches()
    const track = false
    if (await this.getCurrentUser(null, track)) {
      newList[newList.length - 1].id = await this.addSearchInServer(name, filter)
      this.#savedSearches = newList
      this.#persistsSavedSearches()
    }
  }

  async removeSearch(id) {
    this.#savedSearches = this.#savedSearches.filter(x => parseInt(x.id) !== parseInt(id))
    this.#persistsSavedSearches()
    if (await this.getCurrentUser() && id > 0) {
      const { removeSearch } = await window.seezSdk.queryApi(`mutation {removeSearch(id: ${id}) {id}}`)
      const serverIds = removeSearch.map(r => parseInt(r.id))
      this.#savedSearches = this.#savedSearches.filter(x => serverIds.includes(parseInt(x.id)))
      this.#persistsSavedSearches()
    }
  }

  static vueSavedSearchesMixin = {
    data() {
      return {
        savedSearches: window.seezSdk.getSavedSearches()
      }
    },
    methods: {
      addSearch(name, filter) {
        window.seezSdk.addSearch(name, filter)
        this.savedSearches = window.seezSdk.getSavedSearches()
      },
      removeSearch(id) {
        window.seezSdk.removeSearch(id)
        this.savedSearches = window.seezSdk.getSavedSearches()
      }
    }
  }

  async #retrieveUserDetails() {
    if ((await this.getCurrentUser()) == null) return
    const { user } = await this.queryApi('{user{favorites {listingId},savedSearches{id,name,filterParameters{bodyTypes,brands,colors,driveTypes,engineSizeMax,freeText,fuelTypes,kilometrageMin,kilometrageMax,models,numberOfDoorsMax,numberOfDoorsMin,priceMin,priceMax,priceType,sort,transmissions,yearMin,yearMax}}}}')

    const userFavorites = user.favorites.map(l => parseInt(l.listingId))
    const newLocalFavorites = this.getFavorites().filter(f => !userFavorites.includes(f))
    if (newLocalFavorites.length > 0) {
      userFavorites.push(...newLocalFavorites)
      userFavorites.sort()
      this.#favorites = userFavorites
      this.#persistFavorites()
    } else {
      this.#favorites = userFavorites
      localStorage.setItem('favorites', JSON.stringify(this.#favorites))
    }

    const searches = user.savedSearches
    const newLocalSearches = this.getSavedSearches().filter(s => !searches.some(x => x.name === s.name) && s.id < 0)
    this.#savedSearches = searches
    for (const newSearch of newLocalSearches) {
      this.addSearch(newSearch.name, newSearch.filterParameters)
    }
    this.#persistsSavedSearches()
  }

  #cleanUserDetails() {
    this.#favorites = []
    localStorage.removeItem('favorites')
    this.#savedSearches = []
    localStorage.removeItem('searches')
  }
  //#endregion

  //#region Order
  async createOrder(listingId, searchPage) {
    const currentUser = await this.getCurrentUser()
    if (currentUser == null) throw new Error('User not found')
    const { listing, user: { activeOrder } } = await this.queryApi(`{listing(id:${listingId}){state reservation{reservedFor{id}} } user{activeOrder{id,state,listing{id}}}}`)
    if (activeOrder?.listing?.id && parseInt(activeOrder.listing.id) === parseInt(listingId)) return activeOrder.id //the user already started an order for this vehicle
    const isReservedForMe = listing?.reservation && listing?.reservation?.reservedFor?.id?.toString() == currentUser?.id?.toString()

    if (listing.state !== 'available' && !isReservedForMe) {
      return this.showUnavailableListingModal(searchPage, listingId)
    }
    if (activeOrder?.listing?.id) {
      const decision = await this.confirmCancelOrder(activeOrder.listing.id, null)
      if (decision === 'Nothing') return null
      if (decision === 'ResumeCurrent') return activeOrder.id
      if (decision === 'CancelAndStartNew') await this.queryApi(`mutation {cancelOrder(orderId: ${activeOrder.id}) {id} }`)
      if (decision === 'PauseActiveOrder') await this.pauseOrder(activeOrder.id)
    }

    const { createOrder } = await this.queryApi(`mutation{createOrder(input:{listingId:${listingId}}){id}}`)
    return createOrder.id
  }

  async pauseOrder(orderId) {
    const query = `
      mutation pauseOrder($orderId: ID!) {
        pauseOrder(orderId: $orderId) {
          id
          createdOn
          state
        }
      }
    `

    const variables = { orderId: orderId }

    try {
      await this.queryApi(query, variables)
    } catch (err) {
      console.log(err)
    }

  }

  async resumeOrder(orderId) {
    const resumeOrderMutation = `
      mutation reActivateOrder($orderId: ID!) {
        reActivateOrder(orderId: $orderId) {
          id
          createdOn
          state
        }
      }
    `
    const variables = {
      orderId: orderId
    }

    try {
      await this.queryApi(resumeOrderMutation, variables)
    } catch (err) {
      console.log(err)
      throw err
    }
  }

  async createOrderFromTradein(tradeinId, listingId, searchPage) {
    const currentUser = await this.getCurrentUser()
    if ((currentUser) == null) throw new Error('User not found')
    const { listing, user: { activeOrder } } = await this.queryApi(`{listing(id:${listingId}){ state reservation { reservedFor { id } } } } user { activeOrder {id,state,listing{id}} } }`)
    if (activeOrder?.listing?.id && parseInt(activeOrder.listing.id) === parseInt(listingId)) return activeOrder.id //the user already started an order for this vehicle
    const isReservedForMe = listing?.reservation && listing?.reservation?.reservedFor?.id?.toString() == currentUser?.id?.toString()
    if (listing.state !== 'available' && !isReservedForMe) {
      return this.showUnavailableListingModal(searchPage, listingId)
    }
    if (activeOrder?.listing?.id) {
      const decision = await this.confirmCancelOrder(activeOrder.listing.id)
      if (decision === 'Nothing') return null
      if (decision === 'ResumeCurrent') return activeOrder.id
      if (decision === 'CancelAndStartNew') await this.queryApi(`mutation {cancelOrder(orderId: ${activeOrder.id}) {id} }`)
    }

    const { createOrderFromTradeIn } = await this.queryApi(`mutation{createOrderFromTradeIn(tradeinId:${tradeinId},listingId:${listingId},customerId:${currentUser.id}){id}}`)
    return createOrderFromTradeIn.id
  }

  async createOrderFromLead(leadId, listingId, searchPage) {
    const currentUser = await this.getCurrentUser()
    if (currentUser == null) throw new Error('User not found')
    const {
      listing,
      user: { activeOrder }
    } = await this.queryApi(`{listing(id:${listingId}){state reservation { reservedFor { id } } } user{activeOrder{id,state,listing{id}}}}`)
    const isReservedForMe = listing?.reservation && listing?.reservation?.reservedFor?.id?.toString() == currentUser?.id?.toString()

    if (activeOrder?.listing?.id && parseInt(activeOrder.listing.id) === parseInt(listingId)) return activeOrder.id //the user already started an order for this vehicle

    if (listing.state !== 'available' && !isReservedForMe) {
      return this.showUnavailableListingModal(searchPage, listingId)
    }
    if (activeOrder?.listing?.id) {
      const decision = await this.confirmCancelOrder(activeOrder.listing.id, null, true)
      if (decision === 'Nothing') return null
      if (decision === 'ResumeCurrent') return activeOrder.id
      if (decision === 'CancelAndStartNew') await this.queryApi(`mutation {cancelOrder(orderId: ${activeOrder.id}) {id} }`)
      if (decision === 'PauseActiveOrder') {
        await this.pauseOrder(activeOrder.id)
        window.location = `/start/${listingId}`
      }
    }

    const { createOrderFromLead } = await this.queryApi(`mutation{createOrderFromLead(leadId:${leadId},listingId:${listingId},customerId:${currentUser.id}){id}}`)
    return createOrderFromLead.id
  }

  async createOrderFromTestDrive(testDriveId, listingId, searchPage) {
    const currentUser = await this.getCurrentUser()
    if (currentUser == null) throw new Error('User not found')
    const {
      listing,
      user: { activeOrder }
    } = await this.queryApi(`{listing(id:${listingId}){ state reservation { reservedFor { id } } } user{ id activeOrder{ id listing { id } } } }`)
    if (activeOrder?.listing?.id && parseInt(activeOrder.listing.id) === parseInt(listingId)) return activeOrder.id //the user already started an order for this vehicle

    const isReservedForMe = listing?.reservation && listing?.reservation?.reservedFor?.id?.toString() == currentUser?.id?.toString()

    if (listing.state !== 'available' && !isReservedForMe) {
      return this.showUnavailableListingModal(searchPage, listingId)
    }
    if (activeOrder?.listing?.id) {
      const decision = await this.confirmCancelOrder(activeOrder.listing.id, null, true)
      if (decision === 'Nothing') return null
      if (decision === 'ResumeCurrent') return activeOrder.id
      if (decision === 'CancelAndStartNew') await this.queryApi(`mutation {cancelOrder(orderId: ${activeOrder.id}) {id} }`)
      if (decision === 'PauseActiveOrder') {
        await this.pauseOrder(activeOrder.id)
        window.location = `/start/${listingId}`
      }
    }

    const { createOrderFromTestDrive } = await this.queryApi(`mutation{createOrderFromTestDrive(testDriveId:${testDriveId},listingId:${listingId},customerId:${currentUser.id}){id}}`)
    return createOrderFromTestDrive.id
  }

  async confirmCancelOrder(listingId, customPlaceholder, pauseEvent) {
    let promiseResolve = null

    function closed(e) {
      document.body.querySelectorAll('seez-sdk-active-order-cancellation').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve(e.detail[0])
    }

    const orderCancellationComponent = document.createElement('seez-sdk-active-order-cancellation')
    orderCancellationComponent.setAttribute('listing', listingId)
    orderCancellationComponent.setAttribute('placeholder', customPlaceholder)
    orderCancellationComponent.setAttribute('pause', pauseEvent)
    document.body.appendChild(orderCancellationComponent)
    orderCancellationComponent.addEventListener('close', closed)

    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }

  async showCancelOrderModal(order) {
    let promiseResolve = null

    function closed(e) {
      document.body.querySelectorAll('seez-sdk-cancel-order-modal').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve(e.detail[0])
    }

    const modalComponent = document.createElement('seez-sdk-cancel-order-modal')
    modalComponent.addEventListener('close', closed)
    if (order) modalComponent.setAttribute('orderId', order.id)
    document.body.appendChild(modalComponent)

    return new Promise(function (resolve, reject) { promiseResolve = resolve }, false)
  }

  async showUnavailableListingModal(searchUrl, listingId, detailsUrl, similarUrl, lg) {
    let promiseResolve = null

    const closed = e => {
      document.body.querySelectorAll('seez-sdk-unavailable-listing-modal').forEach(x => x.parentNode.removeChild(x))
      if (promiseResolve) promiseResolve(e.detail[0])
    }

    const modalComponent = document.createElement('seez-sdk-unavailable-listing-modal')
    modalComponent.addEventListener('close', closed)
    if (searchUrl) modalComponent.addEventListener('search', () => window.location.href = searchUrl)
    if (listingId) modalComponent.setAttribute('id', listingId)
    if (detailsUrl) modalComponent.setAttribute('to', detailsUrl)
    if (lg) modalComponent.setAttribute('lg', lg)
    if (similarUrl) modalComponent.addEventListener('similar', () => window.location.href = similarUrl)
    document.body.appendChild(modalComponent)

    return new Promise(function (resolve,) { promiseResolve = resolve }, false)
  }

  async beginCheckout(listingId, searchPage) {
    try {
      const orderId = await this.createOrder(listingId, searchPage)
      if (orderId > 0 && window)
        window.location = `/checkout/?order=${orderId}`
    } catch (error) {
      console.error(error)
      this.showMessage(error)
    }
  }
  //#endregion

  //#region Analytics
  enableTracking(segmentID) {
    this.#userPIITracking = true
    if (segmentID) this.loadSegmentTracking(segmentID)
  }

  track(eventKey, properties, vData) {
    return Promise.allSettled([
      this.#internalTrack('track', eventKey, properties, vData?.id),
      this.#segmentTracking(eventKey, properties, vData)
    ])
  }

  trackNavigation(url) {
    this.#internalTrack('page', 'navigation', { url: url ?? window.location.href })
  }

  async #internalTrack(eventType, eventName, details, listingId) {
    const messageBody = {
      eventType,
      eventName,
      clientId: this.clientId,
      url: window?.location?.href,
      occuredAt: toIsoString(new Date()),
      details,
      listingId,
      anonymousId: this.#baseHeaders?.['Seez-Anonymous-Id'],
      sessionId: this.#baseHeaders?.['Seez-Session-Id'],
      userAgent: navigator?.userAgent,
      languages: navigator?.languages
    }

    if (this.#userPIITracking) messageBody.userId = await this.getCurrentUser().then(u => u?.id)

    const payload = {
      'Action': 'SendMessage',
      'MessageAttributes.1.Name': 'operation',
      'MessageAttributes.1.Value.DataType': 'String',
      'MessageAttributes.1.Value.StringValue': 'activityTrack',
      'MessageBody': JSON.stringify(messageBody)
    }

    const body = new URLSearchParams(payload)
    // navigator.sendBeacon(import.meta.env.VITE_SQS_ANALYTICS, body)
    await fetch(import.meta.env.VITE_SQS_ANALYTICS, { method: 'POST', body })
  }

  //#region Segment
  analytics() {
    return typeof window === 'object' && typeof window?.analytics === 'object'
      ? window.analytics
      : Object.assign(
        {},
        ...Object.entries({ ...['track', 'identify', 'page', 'user'] }).map(
          ([, b]) => ({ [b]: e => e })
        )
      )
  }

  loadSegmentTracking(segmentID) {
    this.#userPIITracking = true
    let analytics = window.analytics = window.analytics || []

    if (!analytics.initialize)
      if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
      else {
        analytics.invoked = !0
        analytics.methods = ['identify', 'track', 'page', 'setAnonymousId']
        analytics.factory = function (e) {
          return function () {
            const t = Array.prototype.slice.call(arguments)
            t.unshift(e)
            analytics.push(t)
            return analytics
          }
        }
        for (let e = 0; e < analytics.methods.length; e++) {
          let key = analytics.methods[e]; analytics[key] = analytics.factory(key)
        }
        analytics.load = function (key, e) {
          let t = document.createElement('script')
          t.type = 'text/javascript'
          t.async = !0
          t.src = 'https://cdn.segment.com/analytics.js/v1/' + key + '/analytics.min.js'
          let n = document.getElementsByTagName('script')[0]
          n.parentNode.insertBefore(t, n)
          analytics._loadOptions = e
        }
        analytics._writeKey = segmentID
        analytics.SNIPPET_VERSION = '4.15.3'
        analytics.load(segmentID)
        analytics.page()
      }
  }

  async identify({ userId, name, email, marketing, loginStatus, phone }) {
    if (this.#lastUserId === userId || !this.#userPIITracking) return

    this.#lastUserId = userId
    const payload = {
      userId,
      name,
      email,
      hashedEmail: await sha256(email),
      loginStatus,
      marketing_consent: marketing === 1,
      phone
    }

    return Promise.allSettled([
      this.#internalTrack('identify', 'identify', payload),
      this.analytics().identify(userId, payload)
    ])
  }

  async #segmentTracking(eventKey, properties, vData) {
    await this.getCurrentUser()

    const genCarData = ({ id, brand, kilometrage, year, color, variant, registrationDate, model, fuelType, transmission, bodyType, dealership }) => {
      return {
        vehicle_id: id,
        kmtrage: kilometrage,
        vehicle_year: year,
        vehicle_color: color,
        vehicle_variant: variant,
        vehicle_first_registration_date: registrationDate,
        vehicle_model_name: model?.name,
        vehicle_brand_name: brand?.name,
        vehicle_fuel_type: fuelType?.name,
        vehicle_transmission_type: transmission?.name,
        vehicle_body_type: bodyType?.name,
        vehicle_dealer_id: dealership?.id,
        vehicle_dealer_name: dealership?.name,
      }
    }

    const vehicleData = vData?.vehicle && genCarData(vData.vehicle)
    const storage_anonymousId = localStorage.getItem('ajs_anonymous_id')
    const anonymousId = storage_anonymousId && storage_anonymousId.replace('"', '').replace('"', '') || null

    return new Promise(resolve => {
      this.analytics().track(eventKey,
        { ...properties, vehicleData, anonymousId, clientId: this.clientId },
        { traits: this.#userPIITracking ? this.analytics()?.user?.()?.traits() : null },
        resolve
      )
      if (this.analytics().initialized !== true) resolve()
    })
  }
  //#endregion
  //#endregion
  //#region customCss
  async #injectCustomStyles() {
    try {
      const response = await this.queryApi('query { currentTargetSite { id customCss }} ')
      if (response?.currentTargetSite?.customCss == null) return

      const style = document.createElement('style')
      style.innerHTML = response.currentTargetSite.customCss.trim()
      document.head.appendChild(style)

    } catch (error) {
      console.error('Failed to inject custom styles:', error)
    }
  }
  //#endregion

  //#region chat
  openChat(message = '') {
    const detail = { message }
    const event = new CustomEvent('externallyOpenChatModal', { detail })
    window.dispatchEvent(event)
  }
  //#endregion

  createWebSocket(targetName = '') {
    let ws


    function connectWebSocket() {
      const loopbackAddresses = ['localhost', '127.0.0.1', '::1']
      const isLocalDevelopment = import.meta.env.VITE_LOCAL_DEVELOPMENT === 'true'
      const sdkPort = '6005'
      if (!isLocalDevelopment || !loopbackAddresses.includes(window.location.hostname) || window.location.port === sdkPort) return
      ws = new WebSocket('ws://localhost:8080')

      ws.onmessage = event => {
        if (event.data === 'reload') {
          window.location.reload()
        }
      }

      ws.onopen = () => {
        console.log(`Seez client: [${targetName.toUpperCase()}] is now listening for SDK changes.`)
        ws.send(`Seez client: [${targetName.toUpperCase()}] is now connected to the SDK.`)
      }

      ws.onclose = () => {
        console.log('Disconnected from WebSocket server')
        setTimeout(connectWebSocket, 5000) // Reconnect every 5 seconds
      }

      ws.onerror = error => {
        console.error('WebSocket error:', error)
        ws.close()
      }
    }

    connectWebSocket()
  }
}

